.page-title {
  position: relative;
  padding: 80px 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  &:before {
    content: '';
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba($color: #132646, $alpha: 0.6);
  }
  .title-text {
    h1 {
      position: relative;
      font-size: 40px;
      color: #ffffff;
      line-height: 50px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 10px;
      letter-spacing: 1px;
      font-family: $primary-font;
    }
    .title-menu{
      padding-left: 0;
      margin-bottom: 0;
    }
    .title-menu li {
      position: relative;
      color: #48bdc5;
      font-size: 17px;
      display: inline-block;
      line-height: 30px;
      text-transform: capitalize;
      a {
        margin-right: 8px;
        color: #ffffff;
        font-weight: 500;
        transition: all 500ms ease;
        &:hover {
          color: #48bdc5;
          transition: all 500ms ease;
        }
      }
    }
  }
}