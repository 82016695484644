.navbar {
  margin-bottom: 0;
  background: $primary-color;
  border: none;
  border-radius: 0;

  .navbar-nav {
    @include desktop {
      padding: 15px 0;
    }
    li {
      > .nav-link {
        font-family: $primary-font;
        font-size: 17px;
        color: $light;
        text-transform: uppercase;
        padding: 13px 18px !important;
        transition: .3s;

        @include desktop {
          padding: 6px 18px !important;
          text-align: center;
        }

        &:hover {
          color: $light;
          opacity: .7;
        }
      }

      &.active > {
        a {
          background: transparent;
          font-weight: 600;
          text-decoration: underline;

          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
}

.dropdown-toggle::after {
  border: 0;
  margin-left: 6px;
  vertical-align: 1px;
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  font-size: 11px;
  width: auto;
}

@media (min-width:1200px) {
  .navbar .dropdown-menu {
    display: block;
    transition: 0.2s;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    border: 0;
    background-color: white;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    .dropdown-item {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .navbar .dropdown:hover > .dropdown-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 8px;
  }
}

.dropdown-menu {
  padding: 0 20px 0 5px;
  min-width: auto;
  @include desktop {
    padding: 0;
  }
  li:first-child {margin-top: 10px}
  li:last-child {margin-bottom: 10px}
  
  .dropdown-item {
    color: #333;
    font-size: 16px;
    transition: .3s;
    &:focus,
    &:hover,
    &.active, &:active {
      background-color: transparent;
      color: #333;
    }
    &.active {
      text-decoration: underline;
      font-weight: 500;
    }
    &:hover {
      opacity: .7;
    }
  }
}

.dropdown-submenu {
  a.dropdown-toggle {
    color: #333;
    &::after {
      margin-left: 7px;
      vertical-align: .255em !important;
      transform: scale(1.3);
    }
  }
  .dropdown-menu {
    transform: initial;
    top: -15px;
    @include desktop {
      margin: 0 10px !important;
    }
  }
  &.dropright .dropdown-menu {
    left: calc(100% + 10px);
    margin-left: 0;
  }
  &.dropleft .dropdown-menu {
    left: auto;
    right: 100%;
    margin-right: 0;
  }
}

.dropdown-item {
  @include desktop {
    text-align: center;
  }
}

.navbar-dark .navbar-toggler {
  color: rgba(255,255,255,.5);
  border-color: #cef4f7;
  border-radius: 0;
  padding-left: 8px;
  padding-right: 8px;
  margin: 6px auto;
}
button:focus {
  outline: 0;
}